<template>
  <div id="operproccess__coming">
    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <manufacture-documents-header
        page_type="equipment"
        @date_range="updateDateRange"
        @create_document="create"
      />
      <b-card
        no-body
        class="p-0 b-table__content"
      >
        <documents-table-filter
          ref="doc_table_filter"
          placeholder="№ Документа"
          table_name="equipments-docs"
          @handler_pagination="handlerSearch"
          @clear="clearSearchStr"
        />
        <b-table
          responsive
          :items="items"
          class="table-docs"
          :fields="fields"
          tbody-tr-class="cursor"
          @row-clicked="openDocument"
        >
          <template #table-busy>
            <div class="text-center mt-2">
              <b-spinner
                variant="primary"
                label="Загрузка..."
              />
              <div class="center bold mt-2">Загрузка...</div>
            </div>
          </template>

          <template #head(id)>
            <b-form-checkbox v-model="select_all" />
          </template>
          <template #cell(id)="data">
            <div
              class="b-status"
              :class="{ success: data.item.fixed }"
            ></div>
            <b-form-checkbox
              v-model="selected"
              class="cursor"
              :value="data.value"
            />
          </template>
          <template #cell(date)="data">
            <div class="b-date">{{ data.item.number }}</div>
            <div class="b-date date mt-1">
              {{ data.item.date ? convertDate(data.item.date) : '' }}
            </div>
          </template>
          <template #cell(fixed)="data">
            <b-badge :class="{ success: data.item.fixed }">
              {{ data.item.fixed ? 'Проведен' : 'Черновик' }}
            </b-badge>
          </template>
          <template #cell(sum)="data">
            <span class="d-flex float-right"> {{ formatPrice(data.value) || 0 }} ₽ </span>
          </template>

          <template #cell(retail_sum)="data">
            <span class="d-flex float-right"> {{ formatPrice(data.value) || 0 }} ₽ </span>
          </template>
          <template #cell(owner)="data">
            <span class="d-flex float-left">
              <template v-if="data.item.owner">
                {{ data.item.owner?.first_name }}
                {{ data.item.owner?.last_name }}
              </template>
              <template v-else>–</template>
            </span>
          </template>
        </b-table>
      </b-card>
      <table-navbar
        :clear_selected="clearSelected"
        :items="selected"
        items_name="documents"
        doc_type="manufacture"
        :print="false"
        @remove_items="remove_items"
      />

      <pagination
        :total="items_total"
        table_name="equipments-docs"
        @change_pagination="handlerPagination"
      />
    </div>
  </div>
</template>

<script>
  import ManufactureDocumentsHeader from '@/views/manufacture/components/ManufactureDocumentsHeader'
  import { mapActions, mapGetters } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'
  import formatDate from '@/utils/formatDate'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'

  export default {
    name: 'RepackingList',
    components: {
      ManufactureDocumentsHeader,
      Pagination,
      DocumentsTableFilter,
      TableNavbar
    },
    apollo: {
      ReverseManufactureHeadList: {
        query: require('../../gql/ReverseManufactureHeadList.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch?.__typename === 'BranchClassifModel' ? null : this.currentBranch.id,
              pagination: {
                skip: this.getPagination?.['equipments-docs']?.skip,
                take: this.getPagination?.['equipments-docs'].take,
                search: this.getPagination?.['equipments-docs'].search
              }
            },
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null
          }
        },
        result({ data }) {
          this.selected = []
          this.items = data.ReverseManufactureHeadList.list
          this.items_total = data.ReverseManufactureHeadList.total
        }
      }
    },
    data() {
      return {
        title: this.$route.meta.title,
        items: [],
        select_all: false,
        selected: [],
        items_total: 0,
        fields: [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center',
            thStyle: 'min-width: 48px'
          },
          {
            key: 'date',
            label: 'Дата',
            thStyle: 'min-width: 131px',
            sortable: true
          },
          {
            key: 'sum',
            label: 'Итого по док-ту (₽)',
            sortable: true,
            thStyle: 'min-width: 192px'
          },
          {
            key: 'retail_sum',
            label: 'Итого в роз. ценах по док-ту (₽)',
            thStyle: 'min-width: 181px'
          },
          {
            key: 'fixed',
            label: 'Статус',
            thStyle: 'min-width: 188px',
            class: 'center'
          },
          {
            key: 'owner',
            label: 'Менеджер',
            thStyle: 'min-width: 188px'
          },
          {
            key: 'comment',
            label: 'Комментарий',
            thStyle: 'min-width: 200px'
          }
        ]
      }
    },
    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination'
      })
    },
    methods: {
      ...mapActions({ setPagination: 'settings/set_pagination' }),
      clearSelected() {
        this.selected = []
        this.select_all = false
      },
      updateDateRange() {},
      async create() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/CreateReverseManufactureHead.graphql'),
          variables: {
            input: {
              branch: this.currentBranch.id
            }
          }
        })
        await this.$router.push({
          name: `operation-process.repacking.edit`,
          params: { id: data.CreateReverseManufactureHead.id }
        })
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['equipments-docs']: {
              ...this.getPagination?.['equipments-docs'],
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['equipments-docs']: {
              ...this.getPagination?.['equipments-docs'],
              take
            }
          })
        }
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      formatPrice(sum = 0) {
        const val = sum === null ? 0 : sum
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      handlerSearch(search) {
        this.setPagination({
          ...this.getPagination,
          ['equipments-docs']: {
            ...this.getPagination?.['equipments-docs'],
            search
          }
        })
      },
      clearSearchStr() {
        this.setPagination({
          ...this.getPagination,
          ['equipments-docs']: {
            ...this.getPagination?.['equipments-docs'],
            search: ''
          }
        })
      },
      async remove_items(ids) {
        await this.$apollo.mutate({
          mutation: require('../../gql/DeleteReverseManufactureHead.graphql'),
          variables: {
            ids: ids
          }
        })
        this.$apollo.queries.ReverseManufactureHeadList.refresh()
        this.selected = []
      },
      openDocument(item) {
        this.$router.push({
          name: `operation-process.repacking.edit`,
          params: { id: item.id }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #operproccess__coming {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  ::v-deep .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;

    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }
  :deep .table-docs {
    min-height: calc(100% - 64px) !important;
    padding-bottom: 0 !important;
    max-height: 0 !important;
    th {
      padding: 16px 21px 16px 16px !important;
    }
    table {
      border-top: none;
      border-collapse: separate;
      border-spacing: 0;
      tbody {
        tr:first-child {
          th {
            border-top: none;
          }
        }
      }
      tr:not(:nth-child(1)) {
        td {
          border-top: 1px solid #e1e1e1 !important;
        }
      }
    }
    tr {
      cursor: pointer;
    }
    thead {
      top: 0;
      background-color: #fff;
      position: sticky;
      z-index: 100;
      th {
        border-bottom: 1px solid #e1e1e1 !important;
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bcbcbc;
    }
    th.center {
      position: relative;
      .b-status {
        width: 2px;
        height: 32px;
        position: absolute;
        left: 0px;
        top: 50%;
        background: #bcbcbc;
        border-radius: 0px 1px 1px 0px;
        transform: translate(0%, -50%);

        &.success {
          background: #00cb91;
        }
      }
    }
  }

  @media (max-height: 700px) {
    .table-docs {
      max-height: 100% !important;
    }
  }
</style>
